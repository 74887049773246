.popular-movies {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  

background: #fff;
overflow: hidden;
cursor: pointer;
border: 1rem solid #fff;
box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.4);
}
.popular-box {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  background:transparent;

/*overflow: hidden;
cursor: pointer;
border: 1rem solid #fff;
box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.4);
*/
}
.popular-inner {
padding:0px;
margin:10px;
overflow:hidden;
text-align:center;
}
.popular-inner h6{
padding:0px;
margin:0px;
margin-top:10px;
overflow:hidden;
text-align:center;
}
.popular-inner img{
padding:0px;
margin:0px;
overflow:hidden;
width:100%;
border-radius: 50rem !important;
transition: .5s!important;
--bs-bg-opacity: 1;
background-color: rgba(var(--bs-light-rgb),var(--bs-bg-opacity)) !important;
vertical-align: middle;
box-sizing: border-box;
visibility: visible;
}

.popular-movies div
{
	padding:0px;	
	margin:0px;

}
.imageBox
{
	/*border: 0.1rem solid #000080;*/
	transition: 0.35;
	border-radius: 1.5rem;
	padding: 5px;
}

.popular-movies img {
  width: auto;
  height: auto;
  object-fit: cover;
  border-radius: 1rem;
  margin-bottom: 0rem;
  
background: #fff;
overflow: hidden;
cursor: pointer;
border: 0.1rem solid #fff;
box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.4);
}
.filter-container
{
	margin-bottom:10px;
	margin-top:10px;
}
.filter-container button {
 appearance: none;
  filter: url("data:image/svg+xml;charset=utf-8,<svg xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"filter\"><feGaussianBlur in=\"SourceAlpha\" stdDeviation=\"5\" /><feOffset dx=\"1\" dy=\"6\" result=\"offsetblur\" /><feFlood flood-color=\"rgba%280,0,0,0.1%29\" /><feComposite in2=\"offsetblur\" operator=\"in\" /><feMerge><feMergeNode /><feMergeNode in=\"SourceGraphic\" /></feMerge></filter></svg>#filter");
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .1));
  transition: background .5s ease, color .5s ease;
  border: none;
  background: #ffd800;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;
 }

button:hover {
  transform: scale(1.05);
}
button.active {
  background: #6415FF;
  color: inherit;
  padding:14px 14px;
  margin:0px;
  text-align:center;

  appearance: none;
  filter: url("data:image/svg+xml;charset=utf-8,<svg xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"filter\"><feGaussianBlur in=\"SourceAlpha\" stdDeviation=\"5\" /><feOffset dx=\"1\" dy=\"6\" result=\"offsetblur\" /><feFlood flood-color=\"rgba%280,0,0,0.1%29\" /><feComposite in2=\"offsetblur\" operator=\"in\" /><feMerge><feMergeNode /><feMergeNode in=\"SourceGraphic\" /></feMerge></filter></svg>#filter");
  filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .1));
  transition: background .5s ease, color .5s ease;
  border: none;
  background: #0EC7A7;
  border-radius: 30px;
  text-transform: uppercase;
  box-sizing: border-box;
  padding: 15px 40px;
  font-weight: 400;
  font-size: 13px;
  cursor: pointer;

}
button:hover
{	
	color: #ffffff;
background: #222222;
filter: url("data:image/svg+xml;charset=utf-8,<svg xmlns=\"http://www.w3.org/2000/svg\"><filter id=\"filter\"><feGaussianBlur in=\"SourceAlpha\" stdDeviation=\"5\" /><feOffset dx=\"1\" dy=\"1\" result=\"offsetblur\" /><feFlood flood-color=\"rgba%280,0,0,0.2%29\" /><feComposite in2=\"offsetblur\" operator=\"in\" /><feMerge><feMergeNode /><feMergeNode in=\"SourceGraphic\" /></feMerge></filter></svg>#filter");
filter: drop-shadow(0 0 5px rgba(0, 0, 0, .2));

}
.css-uh1pua
{
  background:#000080 !important;
  color:inherit;
}
.css-uh1pua h6, .css-uh1pua p
{
  background:#000080 !important;


}
.popular-movies h6{
  color: #0e2c53 !important;
  text-align: center;
  padding: 0 10px;
}