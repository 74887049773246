.modalGallery {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 1rem;
  grid-row-gap: 2rem;
  background: #fff;
  overflow: hidden;
  cursor: pointer;
  border: 1rem solid #fff;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.4);
}

svg.css-8wgz7g path
{
	color: #FFC200 !important;

}
