* {
	font-family:'Roboto'!important;
	margin: 0;
	padding: 0;
	colro:#0e2c53;
	list-style:none;
	text-transform:none;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.6;
	color: #74787C;
}
#root
{
	overflow:hidden;
}
.container-fluid
{
	margin:0px;
	padding:0px;
	--bs-gutter-x:0px!important;
}
p{
	color:#0e2c53;
	text-align:justify;
	margin:5px 20px;
	padding:0 0px;
	line-height: 1.6rem;
	
}
footer p
{
	text-align:left;
	line-height: 1.6rem;
	margin:0px;
	padding:0 0px;
	color:#FFFFFF;
}
#contentSection
{
	margin-bottom:50px;
	margin-left:0px;
	margin-right:0px;
}
#contentSection h1,
#contentSection h2,
#contentSection h3,
#contentSection h4,
#contentSection h5,
#contentSection h6,
{
  font-family: 'Lobster Two', cursive!important;
  font-weight: 700!important;
  text-align:center!important;
  colro:#103741!important;
}
#contentSection h1
{
	text-align:center;
}
#contentSection h1 span[class^='color']
{
	font-family: 'Lobster Two', cursive!important;
	font-weight: 700!important;
	text-align:center!important;
	font-size: 2.5rem!important;
}

.loginButtonHome 
{
  margin:0px;
  margin-right: 35px !important;
  padding: 0px;

}
/****************** custom css **************************************************************/

#footerSection footer.css-uh1pua {
  background: #000080 !important;
  color: inherit;
}
#footerSection footer.css-uh1pua p, footer.css-uh1pua h6
{
	color:#FFFFFF;
}
#footerSection a
{
	color:#e60101;
}
#footerSection a:hover
{
	color:#00bfff;
}

#headerSection .MuiBox-root a
 {
 	color:#FFFFFF;
	text-decoration:none;
	margin:0px 0px;
 }
 .css-14ogsoa-MuiModal-root-MuiPopover-root-MuiMenu-root
 {
 	top:84px;
 }
 @media (min-width: 900px) and (max-width: 1048px)
 { 
   .logoText
 {
	word-wrap: break-word!important;
	font-size: 0.7rem!important;
	letter-spacing: 1px!important;
 }
 }

#headerSection .MuiBox-root .css-1t6c9ts a
{
	margin:0px 3px;
}
 .dqMUwc:hover {
  background: #FFC200 !important;
  
 
}
.css-2uchni
{
	text-align:right;
}
.MuiAvatar-img
{
	width:auto!important;
	height:auto!important;
}
.sc-fqkvVR svg, .sc-fqkvVR svg path
{
	margin:0px;
	color:#ffd800!important;
	font-size:35px;
}
.MuiToolbar-root
{
	width:100%;
}
.loader-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: #FFFFFF 
        url("../loading-loader.gif") center
        no-repeat;
    z-index: 1;
}
.MuiDrawer-paperAnchorLeft, MuiDrawer-root
{
	background:transparent!important;
	
	
}
.makeStyles-menuSliderContainer-6
{
opacity: 0.8;
background:transparent!important;
background-color:#0EC7A7!important;
 

transition: 350ms;
	
}
.css-i4bv87-MuiSvgIcon-root
{
	color:#FFFFFF;
}

#root #main h1 span.color1
{
	color:#F73961!important;	
}
#root #main  h1 span.color2
{

	color:#4b68e7!important;
}
#root #main  h1 span.color3
{
	color:#0EC7A7!important;
}

#root #main  h1 span.color4
{
	color:#FFC200!important;
}

#root #main  h1 span.color5
{
	color:#AC26C3!important;
}
#root #main  h1 span.color6
{
	color:#00BDFF!important;
}

#root #main  h1 span.color7
{
	color:#FE5D37!important;
}
#root .color1
{
	color:#4b68e7!important;		
}
#root .color2
{
	color:#0EC7A7!important;

}
#root .color3
{
	color:#F73961!important;
	
}

#root .color4
{
	color:#FFC200!important;
}

#root .color5
{
	color:#AC26C3!important;
}
#root .color6
{
	color:#00BDFF!important;
}

#root .color7
{
	color:#FE5D37!important;
}

/********* BG COLOR  *************/
#root .bgcolor1
{
 background-color:#FFC200!important; 
}
#root .bgcolor2
{

  background-color:#FE5D37!important;
}
#root .bgcolor3
{
  background-color:#AC26C3!important;
}

#root .bgcolor4
{
 background-color:#00BDFF!important;
}

#root .bgcolor5
{

 
 background-color:#0EC7A7!important;
}
#root .bgcolor6
{
	background-color:#4b68e7!important;
 
}

#root .bgcolor7
{

 background-color:#F73961!important;	
}
.MuiList-root .MuiButtonBase-root
{
	display:block;	
}

.MuiMenu-list .MuiMenuItem-root a
{
	display:flex;	
	margin:0px;
	padding:10px 65px!important;
	text-align:left;
	padding-left:20px!important;
	text-decoration:none;
}
.MuiMenu-list .MuiMenuItem-root a:hover
{
	color:#00bfff!important;
}
.MuiMenu-list .MuiMenuItem-root a p
{
	text-align:left;
}