#headerSection
{
	background:#000080!important;
	opacity: 0.9;	
	transition: 350ms;
}
#headerSection nav, #headerSection nav div
{
	background:transparent!important;
}
#headerSection .MuiPaper-root
{
	background:transparent!important;
	box-shadow:none!important;
	transition:none!important;
}
a.dqMUwc, span.fliBcw
{
	color:#FFFFFF!important;
	font-weight:500px;
	font-size:18px;
}
svg.css-i4bv87-MuiSvgIcon-root, svg.css-i4bv87-MuiSvgIcon-root path
{
	color:#ff3008;
}