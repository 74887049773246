.slider {
	position: relative;
	overflow: hidden;
	width:100%;
	& a {
		&.previousButton, &.nextButton {
			font-size: 22px;
			line-height: 0;
			display: block;
			position: absolute;
			
			transform: translateY(-50%);
			transition: all .3s linear;
			z-index: 1;
			padding: 10px;
			text-decoration: none;
			backface-visibility: hidden; /* prevent jump effect when scaling */

			&:not(.disabled):hover {
				transform: translateY(-50%) scale(1.25);
				cursor: pointer;
			}

			& svg {
				& polygon {
					fill: #ffd800;
				}
			}
		}

		&.previousButton {
			left: 20px;
		}

		&.nextButton {
			right: 20px;
		}
	}
}

.sliderContent {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden;
	text-align: center;
	background-size: cover !important;
	
	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		background: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
		bottom: 0;
		left: 0;
	}

	&.hidden {
		visibility: hidden;
	}

	&.previous {
		left: -100%;
		
	}

	&.current {
		left: 0;
	}

	&.next {
		left: 100%;
	}

	&.animateIn,
	&.animateOut {
		transition: all 2s ease;
	}

	&.animateIn {
		&.previous,
		&.next {
			left: 0;
			visibility: visible;

			& p {
				transition-delay: 1.1s;
			}

			& button {
				transition-delay: 1.3s;
			}
			
			& section img {
				transition-delay: 1.3s;
			}

			& section span {
				transition-delay: 1.4s;
			}

			& section span strong {
				transition-delay: 1.5s;
			}
		}
	}

	&.animateOut {
		&.previous {
			left: 100%;
		}

		&.next {
			left: -100%;
		}

		& h1 {
			transition-delay: .3s;
		}

		& p {
			transition-delay: .2s;
		}

		& section span {
			transition-delay: .1s;
		}

		& section span strong {
			transition-delay: 0s;
		}
	}

	&.current,
	&.animateIn {
		& h1, 
		& button,
		& p,
		& section * {
			transform: translateX(0);
			transition-delay: .9s;
			opacity: 1;
		}
	}

	& .inner {
		padding: 0 70px;
		box-sizing: border-box;
		position: absolute;
		width: 100%;
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}

	& h1 {
		font-weight: 900;
		margin: 0 auto;
		max-width: 840px;
		color: inherit;
		font-size: 64px;
		line-height: 1;
		transition: all .3s ease;
		transform: translateY(-20px);
		opacity: 0;
	}

	& p {
		color: inherit;
		font-size: 14px;
		line-height: 1.5;
		margin: 20px auto 30px;
		max-width: 640px;
		transition: all .3s ease;
		transform: translateY(20px);
		opacity: 0;
	}

	& button {
		transition: all .3s ease;
		transform: translateY(20px);
		opacity: 0;
	}

	& section {
		position: absolute;
		bottom: 20px;
		left: 20px;

		& * {
			transition: all .3s ease;
		}

		& span {
			color: inherit;
			font-size: 12px;
			display: inline-block;
			text-align: left;
			line-height: 1.4;
			vertical-align: middle;
			margin-left: 10px;
			transform: translateX(-10px);
			opacity: 0;

			& strong {
				color: inherit;
				font-size: 14px;
				display: block;
				transform: translateY(10px);
				opacity: 0;
			}
		}

		& img {
			width: 40px;
			height: 40px;
			border: solid 2px rgba(255, 255, 255, 0.5);
			border-radius: 100%;
			vertical-align: middle;
			transition: all .5s ease;
			transform: translateX(-20px);
			opacity: 0;
		}
	}
}

.slider h2 {
	padding: 20px;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	max-width: 1500px;
	margin: auto;
	display: inline-block;
	width: 100%;
	color:inherit;
}

.slider h1, .slider p, .slider a {
		text-align: center;
		color:inherit;
}
.slider h1
{
	color:#e60101; 
}
.slider p
{
	color:#00bfff;
}
.slider header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 75px;
	background: #000000;
	z-index: 10;
}

.slider header h1 {
	margin: 0;
		color:inherit;
}

.slider .styles__shields__49Vop {
	padding: 20px;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	max-width: 1500px;
	margin: auto;
}

.slider button {
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
	filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="5" /><feOffset dx="1" dy="6" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,0.1)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
	-webkit-filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .1));
	        filter: drop-shadow(0 5px 5px rgba(0, 0, 0, .1));
	-webkit-transition: background .5s ease, color .5s ease;
	transition: background .5s ease, color .5s ease;
	border: none;
	background: #ffd800;
	border-radius: 30px;
	text-transform: uppercase;
	-webkit-box-sizing: border-box;
	        box-sizing: border-box;
	padding: 15px 40px;
	font-weight: 400;
	font-size: 13px;
	cursor: pointer
}

.slider button:hover {
	color: #ffffff;
	background: #222222;
	filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="5" /><feOffset dx="1" dy="1" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,0.2)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
	-webkit-filter: drop-shadow(0 0 5px rgba(0, 0, 0, .2));
	        filter: drop-shadow(0 0 5px rgba(0, 0, 0, .2));

}

.slider header section {
	max-width: 1500px;
	height: 35px;
	margin: auto;
	padding: 20px;
	background: #ffd800;
}

.slider header section a {
	float: right;
	padding: 3px 0;
		color:#FFFFFF;
}

.slider .center {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
}

.slider .slider-content {
	color: inherit;
	
}

.slider .slider-content:before {
	content: '';
	display: block;
	position: absolute;
	width: 100%;
	height: 100%;
	background: -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0, 0, 0, .9)));
	background: linear-gradient(transparent, rgba(0, 0, 0, .9));
	bottom: 0;
	left: 0;

}

.slider .styles__page__3wr3w {
	width: 100%;
	max-width: 1000px;
	margin: auto;
	margin-bottom: 100px;
}