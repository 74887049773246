/********************************************/
.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel {
  display:block!important;
  width: 100%;
  z-index: 1;
}
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
.owl-carousel {
  position: relative;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0,0,0);
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-touch-callout: none;
}
.owl-carousel, .owl-carousel .owl-item {
  -webkit-tap-highlight-color: transparent;
  position: relative;
}
.osahan-category-item {
  background: #fff;
  margin: 5px 2px;
  border-radius: 3px;
  overflow: hidden;
  text-align: center;
  padding:0px;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
  border: 1px solid #fff;
}
.osahan-category-item:hover {
  border: 1px solid #ff3008;
}
.osahan-category-item img {
  width: 50px !important;
  height: 50px;
  object-fit: cover;
  display: inline-block !important;
  border-radius: 50px;
  box-shadow: 0 .125rem .25rem rgba(0,0,0,.075) !important;
  margin: 2px 0 8px 0;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.owl-carousel img {
  image-rendering: auto;
  vertical-align: middle;
  border-style: none;
}
.osahan-category-item h6 {
  margin:5px 0px;
  font-size: 13px;
  color: #ff3008;
  font-weight: 500;
  line-height: 1.2;
  text-align: center;
}
.osahan-category-item p {
  margin:5px 0px;
  font-size: 12px;
  text-align: center;
}
.osahan-category-item a {
text-decoration:none;
text-transform:none;
}
.osahan-category-item {
  text-align: center;
}

.owl-theme .owl-nav [class*="owl-"] {
  position: absolute;
  bottom: calc(50% - 20px);
  background: #ff3008 none repeat scroll 0 0 !important;
  border-radius: 30px !important;
  box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.2);
  color: #000 !important;
  height: 40px;
  line-height: 40px;
  margin: 0 !important;
    margin-left: 0px;
  text-align: center;
  text-transform: uppercase;
  width: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.owl-theme .owl-nav .owl-next {
  right: 0px;
  margin-right: -16px !important;
}
.owl-theme .owl-nav .owl-prev {
  margin-left: -16px !important;
}
.owl-theme .owl-dots, .owl-theme .owl-nav {
  text-align: center;
}
.list-card .list-card-image {
  position: relative;
}
.list-card .star {
  right: 8px;
  bottom: 8px;
}
.position-absolute {
  z-index: 1;
}
.list-card .star .badge {
  font-size: 11px;
  padding: 5px 5px;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}
.list-card .favourite-heart {
  right: 8px;
  top: 8px;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}
.list-card a {
  color: inherit;
}
.list-card .favourite-heart i {
  background: #fff;
  width: 23px;
  height: 23px;
  display: inline-block;
  text-align: center;
  font-size: 15px;
  line-height: 24px;
  border-radius: 50px;
}
.list-card .favourite-heart i {
  text-align: center;
  font-size: 15px;
  line-height: 24px;
}
.list-card .member-plan {
  left: 8px;
  top: 8px;
}
.list-card .member-plan .badge {
  font-size: 11px;
  padding: 5px 5px;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}
.owl-carousel-four .item {
  padding: 6px 7px;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
}
.icofont-heart::before {
  content: "\EF45";
}
.list-card a:hover {
  color: #0091bc;
}